export default object => {
  const {
    billing,
    identifier,
    clientId,
    name,
    openInvoice,
    product: { value },
  } = object;

  return {
    newClient: !billing?.id,

    identifier,
    clientId,
    clientName: name,
    openInvoice,
    value,

    inputBillingName: object?.billing?.name || '',
    inputBillingDocument: object?.billing?.document || '',
    inputBillingEmail: object?.billing?.email || '',
    inputBillingPhoneNumber: object?.billing?.phoneNumber || '',

    period: '1',
  }
};
