import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';

import { ResponsiveContext, Box, Text, Button } from 'grommet';
import { Clipboard, DownloadOption, UploadOption, Calendar, Search, Chat } from 'grommet-icons';

import { toggleFormManagerLayer } from '../../actions/form-manager';

import rulesName from '../../../../constants/rules';

const OnboardingComponent = (props) => {
  const {
    userLogged: {
      rules
    },
    toggleFormManagerLayer,
  } = props;

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          direction='row'
          justify='center'
          margin={{ horizontal: 'medium', top: '100px' }}
        >
          <Box
            pad='small'
            width='xlarge'
            gap='medium'
          >

            {/* Cabeçalho */}
            <Box
              direction='row'
              gap='xsmall'
              align='baseline'
            >
              <Text size='large' color='dark-5'>Bem-vindo ao módulo de</Text>
              <Text size='xlarge' color='dark-1'>atendimentos.</Text>
            </Box>

            {/* Conteúdo */}
            <Box
              gap='medium'
              direction={ size === 'small' || size === 'medium' ? 'column' : 'row' }
              justify='between'
            >
              {/* Primeira coluna */}
              <Box
                width={
                  size === 'small' || size === 'medium' ? 'xlarge' : 'xlarge'
                }
              >
                <Box
                  margin={{
                    bottom: size === 'small' ? 'large' : 'medium'
                  }}
                >
                  <Text color='dark-2' size='small'>
                    Sabe aquele pedido que seu eleitor fez? Poda de árvore, atendimento médico, implantação de lombada, etc.
                    É aqui que você cadastra!
                  </Text>
                </Box>

                <Box
                  direction='row'
                  gap={ size === 'small' ? 'large' : 'medium' }
                  margin={{ bottom: size === 'small' ? 'large' : 'medium' }}
                >
                  <Box
                    gap={ size === 'small' ? 'medium' : 'small' }
                    align='baseline'
                    width='50vw'
                  >
                    <Clipboard size='medium' color='brand' />

                    <Text size='small' color='dark-2' weight='bold'>
                      Gerencie os atendimentos
                    </Text>

                    <Text size='small' color='dark-2'>
                      Cadastre, edite e exclua os atendimentos.
                    </Text>
                  </Box>

                  <Box
                    gap={ size === 'small' ? 'medium' : 'small' }
                    align='baseline'
                    width='50vw'
                  >
                    <DownloadOption size='medium' color='brand' />

                    <Text size='small' color='dark-2' weight='bold'>
                      Imprima e exporte
                    </Text>

                    <Text size='small' color='dark-2'>
                      Imprima ou exporte a lista de atendimentos com um clique!
                    </Text>
                  </Box>
                </Box>

                <Box
                  direction='row'
                  gap={ size === 'small' ? 'large' : 'medium' }
                  margin={{ bottom: size === 'small' ? 'large' : 'medium' }}
                >
                  <Box
                    gap={ size === 'small' ? 'medium' : 'small' }
                    align='baseline'
                    width='50vw'
                  >
                    <Search size='medium' color='brand' />

                    <Text size='small' color='dark-2' weight='bold'>
                      Busca avançada
                    </Text>

                    <Text size='small' color='dark-2'>
                      Filtre os atendimentos por eleitor, status, origem, categoria e muito mais.
                    </Text>
                  </Box>

                  <Box
                    gap={ size === 'small' ? 'medium' : 'small' }
                    align='baseline'
                    width='50vw'
                  >
                    <Calendar size='medium' color='brand' />

                    <Text size='small' color='dark-2' weight='bold'>
                      Crie lembretes
                    </Text>

                    <Text size='small' color='dark-2'>
                      Precisa verificar no orgão publico o andamento de um pedido.
                      Crie um lembrete para não esquecer.
                    </Text>
                  </Box>
                </Box>

                <Box
                  direction='row'
                  gap={ size === 'small' ? 'large' : 'medium' }
                  margin={{ bottom: size === 'small' ? 'large' : 'medium' }}
                >
                  <Box
                    gap={ size === 'small' ? 'medium' : 'small' }
                    align='baseline'
                    width='50vw'
                  >
                    <Chat size='medium' color='brand' />

                    <Text size='small' color='dark-2' weight='bold'>
                      Deixe comentários
                    </Text>

                    <Text size='small' color='dark-2'>
                      Teve alguma atualização de um pedido? Voce pode deixar isso anotado, é só criar um comentário.
                    </Text>
                  </Box>

                  <Box
                    gap={ size === 'small' ? 'medium' : 'small' }
                    align='baseline'
                    width='50vw'
                  >
                    <UploadOption size='medium' color='brand' />

                    <Text size='small' color='dark-2' weight='bold'>
                      Anexar documentos
                    </Text>

                    <Text size='small' color='dark-2'>
                      Protocolou o pedido no orgão publico? O eleitor enviou um documento?
                      Faça o upload para não perder.
                    </Text>
                  </Box>

                </Box>
              </Box>

              {/* Separador coluna */}
              <Box
                width={ size === 'large' || size === 'xlarge' ? 'xsmall' : 'xxsmall'}
              />

              {/* Segunda coluna */}
              <Box
                gap='small'
                width={ size === 'small' || size === 'medium' ? 'xlarge' : 'xlarge' }
              >
                <Box
                  gap='medium'
                  round={ size === 'small' ? 'small' : 'xsmall' }
                  border={{ color: 'light-4' }}
                  pad={{
                    vertical: size === 'small' ? 'large' : 'medium',
                    horizontal:  size === 'small' ? 'medium' : 'small',
                  }}
                >
                  <Text color='dark-2' size='small'>
                    Mantenha os atendimentos sempre atualizados e não deixe de registrar todos.
                    Assim você terá um histórico completo de tudo que foi solicitado.
                  </Text>

                  <Button
                    primary
                    label='Começar, Cadastrar atendimento'
                    color='neutral-1'
                    title={!rules.includes(rulesName.createTreatment) ? 'Você não tem permissão para cadastrar atendimentos.' : null }
                    disabled={!rules.includes(rulesName.createTreatment)}
                    onClick={() =>
                      toggleFormManagerLayer()
                    }
                  />
                </Box>

                {/*<Text color='dark-2' size='small'>
                  Caso tenha alguma dúvida entre em contato por WhatsApp ou e-mail.
                </Text>

                <Box>
                  <Text color='dark-2' size='small'>Suporte por WhatsApp</Text>
                </Box>*/}

              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

const mapStateToProps = ({ authReducer: { authenticated } }) => ({
  userLogged: authenticated,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    toggleFormManagerLayer,
  }, dispath)
};

export const Onboarding = withRouter(connect(mapStateToProps, mapDispatchToProps)(OnboardingComponent));
