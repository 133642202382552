import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Box, Text, Button, Anchor, Menu } from 'grommet';
import { Notes, Edit, Transaction, Chat, Print, More, Download, Search, Trash, Menu as MenuIcon, Upload, Alarm } from 'grommet-icons';

import { EmptySearches, Placeholder, ErrorsSearches, Pagination, Tag } from '../../../../components';
import { Onboarding } from '../onboarding/onboarding';

import { toggleDetailsLayer,  } from '../../actions/details';
import { generatePrint } from '../../actions/print';
import { generatePrintList } from '../../actions/print-list';
import { generateCsv } from '../../actions/export-csv';
import { toggleFormChangeStatusLayer } from '../../actions/form-change-status';
import { toggleFormCommentLayer } from '../../actions/form-comment';
import { openFormSearchLayer, goToNextPage, goToPrevPage } from '../../actions/form-search';
import { toggleFormManagerLayer } from '../../actions/form-manager';
import { toggleFormDeleteLayer } from '../../actions/form-delete';
import { toggleFormCreateArchiveLayer } from '../../actions/form-archive';
import { toggleFormReminderLayer } from '../../actions/form-reminder';

import { formattedNumber, formattedDateTime } from '../../../../utils';
import rulesName from '../../../../constants/rules';

function SearchResultComponent(props) {
  const {
    userLogged: { rules, client },

    loadingFormSearch,
    dataRows,
    totalOfRows,
    getListError,

    reference,
    electorReference,
    electorName,
    groupId,
    statusId,
    originId,
    categoryId,
    officeId,
    responsibleId,
    description,
    startCreatedDate,
    endCreatedDate,
    documentId,
    documentNumber,
    districtIds,
    departmentId,
    page,
    limit,

    toggleDetailsLayer,
    generatePrint,
    generatePrintList,
    generateCsv,
    toggleFormDeleteLayer,
    toggleFormCreateArchiveLayer,
    toggleFormReminderLayer,
    toggleFormManagerLayer,
    toggleFormChangeStatusLayer,
    toggleFormCommentLayer,
    openFormSearchLayer,
    goToNextPage,
    goToPrevPage,
  } = props;

  if(loadingFormSearch && !getListError) {
    return (
      <Placeholder
        title='Carregando...'
        message='Aguarde que estamos preparando as coisas :)'
      />
    );
  }

  if(getListError) {
    return <ErrorsSearches
      button={{
        label: 'Abrir filtros',
        action: openFormSearchLayer
      }}
    />;
  }

  if(!dataRows.length && client.trl) {
  //if(dataRows.length && !client.trl) {
    return <Onboarding />;
  }

  if(!dataRows.length) {
    return <EmptySearches
      buttonFilters={{
        label: 'Abrir filtros',
        action: openFormSearchLayer,
      }}

      buttonCreate={
        rules.includes(rulesName.createTreatment) ?
          { label: 'Novo atendimento', action: toggleFormManagerLayer } : null
      }
    />;
  }

  const getFilters = () => {
    return {
      reference,
      electorReference,
      electorName,
      groupId,
      statusId,
      originId,
      categoryId,
      officeId,
      responsibleId,
      description,
      startCreatedDate: formattedDateTime(startCreatedDate),
      endCreatedDate: formattedDateTime(endCreatedDate),
      documentId,
      documentNumber,
      districtIds,
      departmentId,
      page: 1,
      limit: totalOfRows,
    }
  }

  const optionsMenuList = () => {
    const options = [];

    if(rules.includes(rulesName.printTreatment)) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'medium', top: 'small' }}>
            <Text size='small'>Imprimir</Text>
          </Box>
        ),
        onClick: () => generatePrintList(getFilters()),
      });
    }

    if(rules.includes(rulesName.exportTreatment)) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'medium', bottom: 'small' }}>
            <Text size='small'>Download Excel (.csv)</Text>
          </Box>
        ),
        onClick: () => generateCsv(getFilters()),
      });
    }

    return options;
  }

  const optionsMenu = (row) => {
    const options = [];

    if(rules.includes(rulesName.editTreatment)) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
            <Edit />
            <Text size='small'>Editar</Text>
          </Box>
        ),
        onClick: () => { toggleFormManagerLayer(row) }
      });
    }

    if(rules.includes(rulesName.editTreatment)) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
            <Transaction />
            <Text size='small'>Trocar status</Text>
          </Box>
        ),
        onClick: () => { toggleFormChangeStatusLayer(row) }
      });
    }

    if(rules.includes(rulesName.createCommetTreatment)) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
            <Chat />
            <Text size='small'>Comentar</Text>
          </Box>
        ),
        onClick: () => toggleFormCommentLayer(row)
      });
    }

    if(rules.includes(rulesName.editTreatment)) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
            <Upload />
            <Text size='small'>Upload arquivo</Text>
          </Box>
        ),
        onClick: () => toggleFormCreateArchiveLayer(row)
      });
    }

    if(rules.includes(rulesName.printTreatment)) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
            <Print />
            <Text size='small'>Imprimir</Text>
          </Box>
        ),
        onClick: () => generatePrint(row)
      });
    }

    options.push({
      label: (
        <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
          <Alarm />
          <Text size='small'>Criar lembrete</Text>
        </Box>
      ),
      onClick: () => toggleFormReminderLayer(row)
    });

    if(rules.includes(rulesName.deleteTreatment)) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
            <Trash color='status-error' />
            <Text size='small'>Excluir</Text>
          </Box>
        ),
        onClick: () => toggleFormDeleteLayer(row)
      });
    }

    return options;
  }

  return (
    <ResponsiveContext.Consumer>
      {size => {
        return (
          <Box
            direction='row'
            justify='center'
            margin={{ horizontal: 'medium', top: '80px' }}
          >
            <Box
              pad='small'
              width='xlarge'
            >
              <Box
                align='center'
                direction='row'
                justify='between'
                margin={{ bottom: 'small' }}
              >
                <Box
                  direction={size === 'small' ? 'column' : 'row'}
                  align={size === 'small' ? 'baseline' : 'center'}
                  gap={size === 'small' ? null : 'small'}
                >
                  <Text size='xlarge'>{formattedNumber(totalOfRows)}</Text>

                  <Text
                    size={size === 'small' ? 'xsmall' : 'medium'}
                    color='dark-6'
                  >
                    {totalOfRows > 1 ? 'atendimentos' : 'atendimento'}
                  </Text>
                </Box>

                <Box
                  direction='row'
                  align='center'
                  gap={size === 'small' ? 'large' : 'medium'}
                >
                  {size === 'small' && (
                    <Menu
                      dropProps={{ align: { right: 'right' } }}
                      icon={<MenuIcon />}
                      items={optionsMenuList()}
                    />
                  )}

                  {size !== 'small' && rules.includes(rulesName.exportTreatment) &&
                    <Button
                      plain
                      title={`Download Excel (.csv) dos ${formattedNumber(totalOfRows)} atendimento(s) encontrado(s)`}
                      icon={<Download />}
                      size='small'
                      margin={{ right: 'xsmall' }}
                      onClick={() => generateCsv(getFilters()) }
                    />
                  }

                  {size !== 'small' && rules.includes(rulesName.printTreatment) &&
                    <Button
                      plain
                      title={`Imprimir os ${formattedNumber(totalOfRows)} atendimento(s) encontrado(s)`}
                      icon={<Print />}
                      size='small'
                      margin={{ right: 'xsmall' }}
                      onClick={() => generatePrintList(getFilters()) }
                    />
                  }

                  <Button
                    plain
                    title='Filtrar atendimentos'
                    icon={<Search />}
                    size='small'
                    onClick={() => openFormSearchLayer() }
                  />

                  {rules.includes(rulesName.createTreatment) &&
                    <Button
                      primary
                      color='neutral-1'
                      size='small'
                      label='Novo'
                      onClick={() => toggleFormManagerLayer() }
                    />
                  }
                </Box>
              </Box>

              <Box
                direction='row'
                pad={{ vertical: 'medium' }}
                border={{ side: 'bottom', color: 'light-5' }}
                gap='small'
              >
                {size !== 'small' && size !== 'medium' && (
                  <Box width='15vw'>
                    <Text color='dark-2'>Ref.</Text>
                  </Box>
                )}

                {size !== 'small' && <Box width='70vw'>
                  <Text color='dark-2'>Descrição</Text>
                </Box>}

                {size !== 'small' && <Box width='30vw'>
                  <Text color='dark-2'>Status</Text>
                </Box>}

                {size !== 'small' && size !== 'medium' && (
                  <Box width='40vw'>
                    <Text color='dark-2'>Eleitor</Text>
                  </Box>
                )}

                <Box width='30vw' />
              </Box>

              {dataRows.map(row => {
                return (
                  <Box
                    key={row.id}
                    align='center'
                    direction='row'
                    pad={{ vertical: size === 'small' ? 'small' : 'xsmall' }}
                    border={{ side: 'bottom', color: 'light-5' }}
                    gap='small'
                  >
                    {size !== 'small' && size !== 'medium' && (
                      <Box width='15vw'>
                        <Anchor
                          title='Abrir detalhes'
                          label={row.reference}
                          onClick={() => { toggleDetailsLayer(row) }}
                        />
                      </Box>
                    )}

                    <Box width='70vw'>
                      <Text color='dark-2' size='small'>{row.description}</Text>
                    </Box>

                    <Box width='30vw' direction='row'>
                      <Tag text={row.status} background={row.statusColor} />
                    </Box>

                    {size !== 'small' && size !== 'medium' && (
                      <Box width='40vw'>
                        <Text color='dark-2' size='small'>{row.electorName}</Text>
                        <Text color='dark-3' size='small'>{row.electorTelephone}</Text>
                      </Box>
                    )}

                    <Box align='end' width='30vw'>
                      <Box direction='row'>
                        <Button
                          title='Abrir detalhes'
                          icon={<Notes color='dark-3' size='medium' />}
                          onClick={() => { toggleDetailsLayer(row) }}
                        />

                        <Menu
                          dropProps={{ align: { right: 'right', top: 'top' } }}
                          icon={<More />}
                          items={optionsMenu(row)}
                        />
                      </Box>
                    </Box>
                  </Box>
                )
              })}

              <Pagination
                totalOfRows={totalOfRows}
                nextPage={goToNextPage}
                prevPage={goToPrevPage}
                filters={{
                  reference,
                  electorReference,
                  electorName,
                  groupId,
                  statusId,
                  originId,
                  categoryId,
                  officeId,
                  responsibleId,
                  description,
                  startCreatedDate: formattedDateTime(startCreatedDate),
                  endCreatedDate: formattedDateTime(endCreatedDate),
                  documentId,
                  documentNumber,
                  districtIds,
                  departmentId,
                  page,
                  limit,
                }}
              />
            </Box>
          </Box>
        )
      }}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ authReducer: { authenticated }, servicesReducer: { searchResult, formSearch } }) => ({
  userLogged: authenticated,

  reference: formSearch.reference,
  electorReference: formSearch.electorReference,
  electorName: formSearch.electorName,
  groupId: formSearch.groupId,
  statusId: formSearch.statusId,
  originId: formSearch.originId,
  categoryId: formSearch.categoryId,
  officeId: formSearch.officeId,
  responsibleId: formSearch.responsibleId,
  description: formSearch.description,
  startCreatedDate: formSearch.startCreatedDate,
  endCreatedDate: formSearch.endCreatedDate,
  documentId: formSearch.documentId,
  documentNumber: formSearch.documentNumber,
  districtIds: formSearch.districtIds,
  departmentId: formSearch.departmentId,
  page: formSearch.page,
  limit: formSearch.limit,

  loadingFormSearch: searchResult.loadingFormSearch,
  dataRows: searchResult.dataRows,
  totalOfRows: searchResult.totalOfRows,
  getListError: searchResult.getListError,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    toggleDetailsLayer,
    generatePrint,
    generatePrintList,
    generateCsv,
    toggleFormDeleteLayer,
    toggleFormCreateArchiveLayer,
    toggleFormReminderLayer,
    toggleFormManagerLayer,
    toggleFormChangeStatusLayer,
    toggleFormCommentLayer,
    openFormSearchLayer,
    goToNextPage,
    goToPrevPage,
  }, dispath)
};

export const SearchResult = connect(mapStateToProps, mapDispatchToProps)(SearchResultComponent);
