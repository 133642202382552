import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Box, Text, Button } from 'grommet';
import { Certificate, Configure, ChatOption, ContactInfo } from 'grommet-icons';

import { Placeholder, ErrorsSearches, Tag } from '../../../../components';
import { showFormManagerLayer } from '../../actions/form-manager';

const SearchResult = (props) => {
  const {
    loadingFormSearch,
    dataRows,
    getListError,

    showFormManagerLayer,
  } = props;

  if(loadingFormSearch && !getListError) {
    return (
      <Placeholder
        title='Carregando...'
        message='Aguarde que estamos preparando as coisas :)'
      />
    );
  }

  if(getListError) {
    return <ErrorsSearches />;
  }

  const statusEnum = {
    'accept': { name: 'Aprovado', color: 'status-ok' },
    'reject': { name: 'Rejeitado', color: 'status-critical' },
    'pending': {
      name: 'Em análise',
      color: 'status-warning',
    },
  };

  const listValid = dataRows.filter(
    ({ enabled }) => enabled
  );

  return (
    <ResponsiveContext.Consumer>
      {size => {
        return (
          <Box
            direction='row'
            justify='center'
            margin={{ horizontal: 'medium', top: '100px' }}
          >
            <Box
              pad='small'
              width='xlarge'
              gap='medium'
            >
              {/* Cabeçalho */}
              <Box
                direction='row'
                gap='xsmall'
                align='baseline'
              >
                <Text size='large' color='dark-5'>Configure o</Text>
                <Text size='xlarge' color='dark-1'>perfil de exibição</Text>
              </Box>

              {/* Conteúdo */}
              <Box
                gap='medium'
                direction={ size === 'small' || size === 'medium' ? 'column' : 'row' }
                justify='between'
              >

                {/* Primeira coluna */}
                <Box
                  width={
                    size === 'small' || size === 'medium' ? 'xlarge' : 'xlarge'
                  }
                  gap='medium'
                >
                  <Text color='dark-2' size='small'>
                    Configurar o perfil de exibição permite personalizar as comunicações
                    entre o mandato ou campanha eleitoral e as pessoas de forma profissional e eficiente.
                  </Text>

                  <Box
                    direction='row'
                    gap={ size === 'small' ? 'large' : 'medium' }
                    margin={{ bottom: size === 'small' ? 'large' : 'medium' }}
                  >
                    <Box
                      gap={ size === 'small' ? 'medium' : 'small' }
                      align='baseline'
                      width='50vw'
                    >
                      <Configure size='medium' color='brand' />

                      <Text size='small' color='dark-2' weight='bold'>
                        Flexibilidade
                      </Text>

                      <Text size='small' color='dark-2'>
                        Escolha quais dados compartilhar e mantenha a privacidade de informações sensíveis.
                      </Text>
                    </Box>

                    <Box
                      gap={ size === 'small' ? 'medium' : 'small' }
                      align='baseline'
                      width='50vw'
                    >
                      <Certificate size='medium' color='brand' />

                      <Text size='small' color='dark-2' weight='bold'>
                        Comunicação segura
                      </Text>

                      <Text size='small' color='dark-2'>
                        Os cidadões saberão que estão falando diretamente com o seu escritório,
                        fortalecendo o relacionamento.
                      </Text>
                    </Box>
                  </Box>

                  <Box
                    direction='row'
                    gap={ size === 'small' ? 'large' : 'medium' }
                    margin={{ bottom: size === 'small' ? 'large' : 'medium' }}
                  >
                    <Box
                      gap={ size === 'small' ? 'medium' : 'small' }
                      align='baseline'
                      width='50vw'
                    >
                      <ChatOption size='medium' color='brand' />

                      <Text size='small' color='dark-2' weight='bold'>
                        Facilidade
                      </Text>

                      <Text size='small' color='dark-2'>
                        Facilitar o retorno dos cidadões por canais oficiais de contato.
                      </Text>
                    </Box>

                    <Box
                      gap={ size === 'small' ? 'medium' : 'small' }
                      align='baseline'
                      width='50vw'
                    >
                      <ContactInfo size='medium' color='brand' />

                      <Text size='small' color='dark-2' weight='bold'>
                        Centralizar a comunicação
                      </Text>

                      <Text size='small' color='dark-2'>
                        Todas as comunicações terão a sua identidade, aumentando a confiança dos cidadões.
                      </Text>
                    </Box>
                  </Box>

                  <Button
                    primary
                    color='neutral-1'
                    label={ listValid.length ? 'Editar perfil de exibição' : 'Criar perfil de exibição' }
                    onClick={
                      () => showFormManagerLayer()
                    }
                  />
                </Box>

                {/* Separador coluna */}
                <Box
                  width={ size === 'large' || size === 'xlarge' ? 'xsmall' : 'xxsmall' }
                />

                {/* Segunda coluna */}
                <Box
                  gap='small'
                  width={ size === 'small' || size === 'medium' ? 'xlarge' : 'xlarge' }
                >
                  {!!listValid.length && (
                    <Box
                      round='xsmall'
                      border={{ color: 'light-4' }}
                      background='white'
                    >
                      {listValid.map(({ id, displayName, displayPhoneNumber, displayEmail, status }) => (
                          <Box
                            key={id}
                            align='center'
                            direction='row'
                            pad={{
                              horizontal: size === 'small' ? 'large' : 'medium',
                              vertical: size === 'small' ? 'medium' : 'small'
                            }}
                            border={{ color: 'light-3', side: 'bottom' }}
                            justify='between'
                            gap='large'
                          >
                            <Box
                              align='start'
                            >
                              <Text color='dark-2' size='medium'>{displayName}</Text>

                              <Text
                                color='dark-4'
                                size='xsmall'
                              >
                                {displayPhoneNumber}
                              </Text>

                              <Text
                                color='dark-4'
                                size='xsmall'
                              >
                                {displayEmail}
                              </Text>
                            </Box>

                            <Box>
                              <Tag
                                text={statusEnum[status].name}
                                background={statusEnum[status].color}
                                textSize='xsmall'
                              />
                            </Box>
                          </Box>
                        )
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        )
      }}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ settingsProfileReducer: { searchResult } }) => ({
  loadingFormSearch: searchResult.loadingFormSearch,
  dataRows: searchResult.dataRows,
  getListError: searchResult.getListError,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    showFormManagerLayer,
  }, dispath)
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchResult)
);
