import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { ResponsiveContext, Box, Anchor, Text } from 'grommet';
import { Storage, Notification } from 'grommet-icons';

import { Placeholder, ErrorsSearches } from '../../../../components';

import CardVoter from '../card-voter/card-voter';
import CardService from '../card-service/card-service';
import EmptyCard from '../empty-card/empty-card';

import { toggleTodoLayer } from '../../actions/todo';
import { formattedNumber } from '../../../../utils';

import urlRoutes from '../../../../config/url-routes';

const ResultComponent = (props) => {
  const {
    userLogged: { user },

    serviceTotal,
    votersTotal,

    detailService,
    detailVoter,
    detailVoterDistrict,

    totalPendingTask,
    getDetailsError,
    history,
    toggleTodoLayer,
  } = props;

  if((!detailService || !detailVoter || !detailVoterDistrict) && !getDetailsError) {
    return (
      <Placeholder
        title='Carregando...'
        message='Aguarde que estamos preparando as coisas :)'
      />
    );
  }

  if(getDetailsError) {
    return <ErrorsSearches />;
  }

  return (
    <ResponsiveContext.Consumer>
      {size => {
        return (
          <Box
            direction='row'
            justify='center'
            margin={{ horizontal: 'medium', top: '80px' }}
          >
            <Box
              pad='small'
              width='xlarge'
            >
              <Box
                direction={ size === 'small' ? 'column' : 'row' }
                gap='large'
                justify='between'
                margin={{ bottom: 'xlarge' }}
              >
                {/* welcome */}
                <Box
                  direction={ size === 'small' || size === 'medium' ? 'column' : 'row' }
                  gap={ size === 'small' ? null : 'xsmall' }
                  align={ size === 'small' || size === 'medium' ? 'start' : 'end' }
                >
                  <Text size='large' color='dark-5' weight={400}>Olá, Bem-vindo,</Text>
                  <Text size='xlarge' color='dark-1' weight={500}>{user.name}</Text>
                </Box>


                {/* base de dados e alertas */}
                <Box
                  direction='row'
                  gap='medium'
                  align='end'
                  justify='between'
                >
                  <Box>
                    <Notification color={ totalPendingTask > 0 ? 'status-warning' : 'status-ok' } />
                    <Anchor
                      label={`${totalPendingTask} tarefa(s)`}
                      color='dark-3'
                      size='small'
                      title={totalPendingTask > 0 ? 'Você têm tarefas para hoje' : 'Nenhuma tarefa para hoje!'}
                      onClick={
                        () => {
                          toggleTodoLayer({
                            id: user.id,
                            profileId: user.profileId,
                            clientId: user.clientId,
                          });
                        }
                      }
                    />
                  </Box>

                  <Box>
                    <Storage color='dark-3' />
                    <Text color='dark-3' size='small' truncate>Base de dados</Text>
                  </Box>

                  <Box
                    align='end'
                  >
                    <Text color='brand' size='xlarge'>{formattedNumber(votersTotal)}</Text>
                    <Anchor
                      label='Eleitores'
                      color='dark-3'
                      size='small'
                      onClick={() => history.push(urlRoutes.voterListUrl)}
                    />
                  </Box>

                  <Box
                    align='end'
                  >
                    <Text color='brand' size='xlarge'>{formattedNumber(serviceTotal)}</Text>
                    <Anchor
                      label='Atendimentos'
                      color='dark-3'
                      size='small'
                      onClick={() => history.push(urlRoutes.serviceListUrl)}
                    />
                  </Box>
                </Box>
              </Box>


              <Box
                gap='large'
                direction={ size === 'small' || size === 'medium' ? 'column' : 'row' }
                align={ size === 'small' || size === 'medium' ? 'baseline' : 'center' }
                margin={{ bottom: 'large' }}
              >
                <Box
                  gap='small'
                  width={ size === 'small' || size === 'medium' ? 'xlarge' : 'medium' }
                >

                  <Text color='brand' size='medium'>
                    Bairros com maior índice de pessoas cadastradas.
                  </Text>

                  {user.profileId === 1 && detailVoterDistrict.length > 0 && (
                    <Box
                      direction='row'
                      gap='xxsmall'
                    >
                      <Text color='dark-2' size='small'>Acompanhe mais em</Text>
                      <Anchor
                        href={urlRoutes.analyticsUrl}
                        label='Analytics'
                        color='brand'
                        size='small'
                        target='_blank'
                      />
                    </Box>
                  )}
                </Box>

                <Box
                  direction={ size === 'small' ? 'column' : 'row' }
                  gap={ size === 'small' ? 'large' : 'small' }
                  fill
                >
                  {detailVoterDistrict.map((row, index) => (
                    <CardVoter
                      key={index}
                      name={row.name}
                      total={row.total}
                      voters={votersTotal}
                    />
                  ))}

                  {detailVoterDistrict.length === 0 && (
                    <EmptyCard
                      link={{
                        label: 'Eleitores',
                        action: () => history.push(urlRoutes.voterListUrl),
                      }}
                    />
                  )}
                </Box>
              </Box>


              <Box
                gap='large'
                direction={
                  size === 'small' || size === 'medium' ? 'column' : 'row'
                }
                align={
                  size === 'small' || size === 'medium' ? 'baseline' : 'center'
                }
                margin={{ vertical: 'medium' }}
              >
                <Box
                  gap='small'
                  width={ size === 'small' || size === 'medium' ? 'xlarge' : 'medium' }
                >
                  <Text color='brand' size='medium'>
                    Número de atendimentos por status.
                  </Text>

                  {user.profileId === 1 && detailService.length > 0 && (
                    <Box
                      direction='row'
                      gap='xxsmall'
                    >
                      <Text color='dark-2' size='small'>Acompanhe mais em</Text>
                      <Anchor
                        href={urlRoutes.analyticsUrl}
                        label='Analytics'
                        color='brand'
                        size='small'
                        target='_blank'
                      />
                    </Box>
                  )}
                </Box>

                <Box
                  direction={ size === 'small' ? 'column' : 'row' }
                  gap={ size === 'small' ? 'large' : 'small' }
                  fill
                >
                  {detailService.map((row, index) => (
                    <CardService
                      key={index}
                      status={row.name}
                      color={row.color}
                      total={row.total}
                      services={serviceTotal}
                    />
                  ))}

                  {detailService.length === 0 && (
                    <EmptyCard
                      link={{
                        label: 'Atendimentos',
                        action: () => history.push(urlRoutes.serviceListUrl),
                      }}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        )
      }}
    </ResponsiveContext.Consumer>
  );
}

const mapStateToProps = ({ authReducer: { authenticated }, dashboardSystemReducer: { details } }) => ({
  userLogged: authenticated,

  serviceTotal: details.serviceTotal,
  votersTotal: details.votersTotal,

  detailService: details.detailService,
  detailVoter: details.detailVoter,
  detailVoterDistrict: details.detailVoterDistrict,

  totalPendingTask: details.totalPendingTask,

  getDetailsError: details.getDetailsError,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    toggleTodoLayer,
  }, dispath)
};

export const Result = withRouter(connect(mapStateToProps, mapDispatchToProps)(ResultComponent));
