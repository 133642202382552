import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Box, Text, TextInput, Select, MaskedInput, Anchor } from 'grommet';

import { TitleField } from '../../../../components/form';
import { ErrorForm } from '../../../../components';

import selectGenreOptions from '../../../../constants/select-genre-options';

import { getVotersOptions } from '../../../../actions/select-options';
import { changeField } from '../../actions/form-create';

function Settings(props) {
  const {
    groupOptions,
    votersOptions,

    id,
    name,
    individualRegistration,
    nickName,
    dateOfBirth,
    genre,
    groupsId,
    postDataError,

    changeField,
    getVotersOptions,
  } = props;

  const onSearchOtherVoters = (text) => {
    if (text.length < 3 || id)
      return;

    getVotersOptions({ name: text });
  };

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <Box margin={{ bottom: 'medium' }}>
            <Text size='large'>
              Dados do eleitor(a)
            </Text>
          </Box>

          <Box
            direction={size !== 'small' && size !== 'medium' ? 'row' : 'column-reverse'}
          >
            {/* coluna 1 - settings */}
            <Box
              margin={{ right: 'large' }}
              width={size !== 'small' && size !== 'medium' ? '50vw' : '100vw'}
            >
              <Box margin={{ vertical: 'small' }}>
                <TitleField text={'Nome'} required />

                <TextInput
                  autoComplete='disabled'
                  maxLength={50}
                  value={name ?? ''}
                  onChange={event => {
                    changeField({ fieldName: 'name', value: event.target.value })
                    onSearchOtherVoters(event.target.value)
                  }}
                  // onSuggestionSelect={null}
                  // onSuggestionsOpen={null}
                  /* suggestions={!id ? (
                    votersOptions.map(({ id, name, telephone, districtName, cityName }) => ({
                      label: (
                        <Box
                          pad='small'
                          title='adsfadf'
                        >
                          <Text size='medium' color='dark-1'>{name}</Text>
                          <Text size='xsmall' color='dark-5'>{telephone}</Text>
                          <Text size='xsmall' color='dark-5'>{districtName}, {cityName}</Text>
                        </Box>
                      ),
                      value: null
                    }))
                  ) : null} */
                />

                <ErrorForm errorsList={postDataError.errors} fieldName={['name', 'duplicated']} />
              </Box>

              {/* Nome/Apelido */}
              <Box
                direction={ size === 'small' ? 'column' : 'row' }
                gap='small'
              >
                <Box
                  margin={{ vertical: 'small' }}
                  width={ size === 'small' ? '100vw' : '40vw' }
                >
                  <TitleField text={'CPF'} />

                  <MaskedInput
                    mask={[
                      { length: 3, regexp: /\d/ },
                      { fixed: '.' },
                      { length: 3, regexp: /\d/ },
                      { fixed: '.' },
                      { length: 3, regexp: /\d/ },
                      { fixed: '-' },
                      { length: 2, regexp: /\d/ }
                    ]}
                    value={individualRegistration ?? ''}
                    placeholder='000 000 000 00'
                    onChange={event => changeField({ fieldName: 'individualRegistration', value: event.target.value })}
                  />
                </Box>

                <Box
                  margin={{ vertical: 'small' }}
                  width={ size === 'small' ? '100vw' : '70vw' }
                >
                  <TitleField text={'Apelido'} />

                  <TextInput
                    maxLength={20}
                    value={nickName ?? ''}
                    onChange={event => changeField({ fieldName: 'nickName', value: event.target.value })}
                  />
                </Box>
              </Box>

              {/* data nascimento e genero */}
              <Box
                direction={ size === 'small' ? 'column' : 'row' }
                gap='small'
              >
                <Box
                  margin={{ vertical: 'small' }}
                  width={ size === 'small' ? '100vw' : '40vw' }
                >
                  <TitleField text='Data de nascimento' />

                  <MaskedInput
                    mask={[
                      { length: 2, regexp: /\d/ },
                      { fixed: '/' },
                      { length: 2, regexp: /\d/ },
                      { fixed: '/' },
                      { length: 4, regexp: /\d/ },
                    ]}
                    value={dateOfBirth ?? ''}
                    placeholder='00 00 0000'
                    onChange={event => changeField({ fieldName: 'dateOfBirth', value: event.target.value })}
                  />

                  <ErrorForm errorsList={postDataError.errors} fieldName='dateOfBirth' />
                </Box>

                <Box
                  margin={{ vertical: 'small' }}
                  width={ size === 'small' ? '100vw' : '70vw' }
                >
                  <TitleField text='Genero' required />

                  <Select
                    options={selectGenreOptions}
                    value={genre ?? ''}
                    labelKey='name'
                    valueKey={{ key: 'id', reduce: true }}
                    onChange={({ value: nextValue }) => changeField({ fieldName: 'genre', value: nextValue })}
                    emptySearchMessage='Selecione uma opção'
                  />

                  <ErrorForm errorsList={postDataError.errors} fieldName='genre' />
                </Box>
              </Box>

              <Box margin={{ vertical: 'small' }}>
                <Box direction='row' align='center' gap='xsmall'>
                  <TitleField text={'Grupo'} />

                  {groupsId && groupsId.length > 0 && (
                    <Anchor
                      size='small'
                      label='limpar'
                      onClick={() => changeField({ fieldName: 'groupsId', value: null })}
                    />
                  )}
                </Box>

                <Select
                  options={groupOptions}
                  value={groupsId ?? ''}
                  labelKey='name'
                  valueKey={{ key: 'id', reduce: true }}
                  multiple
                  closeOnChange={false}
                  onChange={({ value: nextValue }) => changeField({ fieldName: 'groupsId', value: nextValue })}
                  emptySearchMessage='Selecione uma opção'
                  valueLabel={
                    groupsId && groupsId.length > 1 ? (
                      <Box pad='small'>
                        <Text>{`${groupsId.length} selecionado(s)`}</Text>
                      </Box>
                    ) : null
                  }
                />
              </Box>
            </Box>

            {/* coluna 2 - settings */}
            <Box
              margin={{
                bottom: 'small',
                left: size !== 'small' && size !== 'medium' ? 'large' : 'none'
              }}
              width={
                size !== 'small' &&
                size !== 'medium' ? '40vw' : '100vw'
              }
            >
              {!id && name?.length > 2 && votersOptions?.length ? (
                <>
                  <Box
                    round={{ size: 'xsmall', corner: 'top' }}
                    border={[
                      { color: 'light-5', side: 'top' },
                      { side: 'left' },
                      { side: 'right' },
                    ]}
                    pad={{ vertical: 'small', horizontal: 'small' }}
                    gap='xxsmall'
                    background='light-1'
                  >
                    <Text
                      size='small'
                      color='dark-2'
                    >
                      O cadastro que esta fazendo não é algum desses?
                    </Text>
                  </Box>

                  <Box
                    round={{ size: 'xsmall', corner: 'bottom' }}
                    border={{ color: 'light-5' }}
                    pad={{ vertical: 'small', horizontal: 'small' }}
                    height='small'
                    overflow='auto'
                  >
                    {
                      votersOptions.map(({ id, name, telephone, districtName, cityName }) => (
                        <Box
                          key={id}
                          direction='column'
                        >
                          <Text size='small' color='dark-1'>{name}</Text>
                          <Text size='xsmall' color='dark-5'>{telephone}</Text>
                          <Text size='xsmall' color='dark-5'>{districtName}, {cityName}</Text>
                        </Box>
                      ))
                    }
                  </Box>
                </>
              ) : null}
            </Box>
          </Box>
        </>
      )}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ votersReducer: { formManager }, selectOptionsReducer }) => ({
  groupOptions: selectOptionsReducer.groupsOptions,
  votersOptions: selectOptionsReducer.votersOptions,

  id: formManager.id,
  name: formManager.name,
  individualRegistration: formManager.individualRegistration,
  nickName: formManager.nickName,
  dateOfBirth: formManager.dateOfBirth,
  genre: formManager.genre,
  groupsId: formManager.groupsId,
  postDataError: formManager.postDataError,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    getVotersOptions,
    changeField,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
