import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ResponsiveContext, Box, Text, Anchor } from 'grommet';

import { Placeholder } from '../../../../components';
import { formattedNumber } from '../../../../utils';

import urlRoutes from '../../../../config/url-routes';

const DashboardResult = (props) => {
  const {
    userLogged,

    summary,
    profileDisplay,
    getSummaryError,

    history,
  } = props;

  if(!summary && !profileDisplay && !getSummaryError) {
    return (
      <Placeholder
        title='Carregando...'
        message='Aguarde que estamos preparando as coisas :)'
      />
    );
  }

  if(getSummaryError) {
    return (
      <Placeholder
        title='Ops! Algo deu errado :('
        message='Estamos constrangidos por isso.'
      />
    );
  }


  const urlParticipativa = userLogged?.client?.shortIdentifier
    ? `https://participativa.gerenciameumandato.com.br/${userLogged.client.shortIdentifier}`
    : null;

  const hasProfileConfigured = profileDisplay?.data?.some(
    ({ enabled, status }) => enabled && status === 'accept'
  );

  return (
    <ResponsiveContext.Consumer>
      {size => {
        return (
          <Box
            direction='row'
            justify='center'
            margin={{ horizontal: 'medium', top: '100px' }}
          >
            <Box
              pad='small'
              width='xlarge'
              gap='medium'
            >
              {/* Cabeçalho */}
              <Box
                direction='row'
                gap='xsmall'
                align='baseline'
              >
                <Text size='large' color='dark-5'>Bem-vindo ao módulo</Text>
                <Text size='xlarge' color='dark-1'>Participativa.</Text>
              </Box>

              {/* Conteúdo */}
              <Box
                gap='medium'
                direction={ size === 'small' || size === 'medium' ? 'column' : 'row' }
                justify='between'
              >
                {/* Primeira coluna */}
                <Box
                  gap={ size === 'small' ? 'xlarge' : 'large' }
                  width={ size === 'small' || size === 'medium' ? 'xlarge' : 'large' }
                >
                  <Box
                    gap={ size === 'small' ? 'medium' : 'small' }
                  >
                    <Text
                      color='dark-2'
                      size='small'
                    >
                      O Participativa é uma maneira rápida e fácil para as pessoas
                      entrarem em contato com seu escritório político.
                    </Text>

                    <Text
                      color='dark-2'
                      size='small'
                    >
                      Com essa ferramenta as pessoas podem enviar elogios,
                      reclamações, fazer um pedido e muito mais.
                    </Text>
                  </Box>


                  <Box
                    gap={ size === 'small' ? 'medium' : 'small' }
                  >
                    <Text color='brand' size='medium'>Como funciona?</Text>

                    <Box
                      round={ size === 'small' ? 'small' : 'xsmall'}
                      border={{
                        side: 'all',
                        color: 'light-4',
                      }}
                      pad={{
                        vertical: size === 'small' ? 'medium' : 'small',
                        horizontal: 'medium',
                      }}
                      align='center'
                      direction='row'
                      gap='medium'
                    >
                      <Text color='dark-2' size='xlarge' weight='bold'>1.</Text>

                      <Text
                        color='dark-2'
                        size='small'
                      >
                        Compartilhe o link ao lado nas redes sociais, e-mail, WhatsApp.
                      </Text>
                    </Box>

                    <Box
                      round={ size === 'small' ? 'small' : 'xsmall'}
                      border={{
                        side: 'all',
                        color: 'light-4',
                      }}
                      pad={{
                        vertical: size === 'small' ? 'medium' : 'small',
                        horizontal: 'medium',
                      }}
                      align='center'
                      direction='row'
                      gap='medium'
                    >
                      <Text color='dark-2' size='xlarge' weight='bold'>2.</Text>

                      <Text
                        color='dark-2'
                        size='small'
                      >
                        As pessoas acessam o link compartilhado de um computador, tablet ou celular,
                        preenche com sua manifestação e envia.
                      </Text>
                    </Box>

                    <Box
                      round={ size === 'small' ? 'small' : 'xsmall'}
                      border={{
                        side: 'all',
                        color: 'light-4',
                      }}
                      pad={{
                        vertical: size === 'small' ? 'medium' : 'small',
                        horizontal: 'medium',
                      }}
                      align='center'
                      direction='row'
                      gap='medium'
                    >
                      <Text color='dark-2' size='xlarge' weight='bold'>3.</Text>

                      <Text
                        color='dark-2'
                        size='small'
                      >
                        A manifestação cai direto no sistema,
                        apartir dai é só cuidar com carinho da manifestação.
                      </Text>
                    </Box>
                  </Box>
                </Box>

                {/* Separador coluna */}
                <Box
                  width={ size === 'large' || size === 'xlarge' ? 'xsmall' : 'xxsmall'}
                />

                {/* Segunda coluna */}
                <Box
                  gap='small'
                  width={ size === 'small' || size === 'medium' ? 'xlarge' : 'large' }
                >
                  <Box
                    border={{
                      color: 'light-4',
                      side: 'all',
                    }}
                    round='xsmall'
                    background='white'
                  >
                    <Box
                      margin={{
                        vertical: 'medium',
                        horizontal: 'medium'
                      }}
                    >
                      <Text size='large' color='dark-1'>Visão geral</Text>
                    </Box>

                    <Box
                      border={{
                        color: 'light-4',
                        side: 'top',
                      }}
                    />

                    <Box
                      margin={{ vertical: 'medium', horizontal: 'medium' }}
                      direction='row'
                      justify='between'
                      gap='large'
                    >
                      <Box
                        gap='xsmall'
                        pad={{ horizontal: 'xsmall' }}
                        border={{
                          side: 'left',
                          color: 'write'
                        }}
                      >
                        <Text size='small' color='dark-2'>Total</Text>
                        <Text size='xlarge' color='dark-1'>{formattedNumber(summary.total)}</Text>
                      </Box>

                      <Box
                        gap='xsmall'
                        pad={{ horizontal: 'xsmall' }}
                      >
                        <Text size='small' color='dark-2'>Pendentes</Text>
                        <Text size='xlarge' color='dark-1'>{formattedNumber(summary.pendent)}</Text>
                      </Box>

                      <Box
                        gap='xsmall'
                        pad={{ horizontal: 'xsmall' }}
                      >
                        <Anchor
                          color='brand'
                          label={<Text size='small'>Acompanhar mais dados e estatísticas</Text>}
                          onClick={ () => history.push(urlRoutes.manifestationsListUrl) }
                        />
                      </Box>

                    </Box>

                    {/*<Box
                      border={{
                        color: 'light-4',
                        side: 'top',
                      }}
                      pad={{ vertical: 'small' }}
                      margin={{ vertical: 'xsmall', horizontal: 'medium' }}
                      direction='row'
                      justify='center'
                      gap='small'
                    >
                      <Anchor
                        color='brand'
                        label={<Text size='small'>mais dados e estatísticas</Text>}
                        onClick={ () => history.push(urlRoutes.voterListUrl) }
                      />
                    </Box>*/}
                  </Box>

                  {!hasProfileConfigured && (
                    <Box
                      round='xsmall'
                      border={{
                        color: 'accent-4',
                        style: 'dashed'
                      }}
                      background='white'
                      pad={{
                        vertical: size === 'small' ? 'medium' : 'small',
                        horizontal: size === 'small' ? 'medium' : 'small',
                      }}
                    >
                      <Text size='small' color='status-warning'>
                        Configure o perfil de exibição para personalizar a página do Participativa.
                      </Text>

                      {userLogged?.user?.profileId === 1 && (
                        <Anchor
                          color='brand'
                          label={<Text size='small'>Iniciar configuração</Text>}
                          onClick={
                            () => history.push(urlRoutes.settingsProfileUrl)
                          }
                        />
                      )}
                    </Box>
                  )}

                  {urlParticipativa && (
                    <>
                      <Box
                        margin={{ vertical: 'small' }}
                      >
                        <Text color='dark-2' size='small'>
                          Compartilhe o endereço abaixo nas redes sociais e facilite a comunicação com os cidadões.
                        </Text>
                      </Box>

                      <Box
                        round='xsmall'
                        border={{
                          color: 'light-4',
                          style: 'dashed'
                        }}
                        background='white'
                        pad={{
                          vertical: size === 'small' ? 'medium' : 'small',
                          horizontal: size === 'small' ? 'medium' : 'small',
                        }}
                        direction='row'
                        align='center'
                        justify='between'
                        truncate
                      >
                        <Anchor
                          color='brand'
                          href={urlParticipativa}
                          target='_blank'
                          label={
                            <Text size='small' truncate>{urlParticipativa}</Text>
                          }
                        />
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        )
      }}
    </ResponsiveContext.Consumer>
  );
}

const mapStateToProps = ({ authReducer: { authenticated }, manifestationReducer: { dashboard } }) => ({
  userLogged: authenticated,

  summary: dashboard.summary,
  profileDisplay: dashboard.profileDisplay,
  getSummaryError: dashboard.getSummaryError,
});

const mapDispatchToProps = null;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardResult));
